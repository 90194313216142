<template>
	<div>
		<b-card>
			<div class="form-filter w-100 mb-2 flex-fill d-flex justify-content-between">
				<v-select
					v-model="formId"
					class="flex-fill"
					clearable
					filterable
					searchable
					:options="forms"
					:reduce="(o) => o.id"
					placeholder="Select a form"
				/>

				<v-select
					v-model="cityName"
					class="flex-fill"
					clearable
					filterable
					searchable
					:options="$store.state.attributes.form.cities"
					:reduce="(o) => o"
					placeholder="Select a city"
				/>

				<v-select
					v-model="projectId"
					class="flex-fill"
					clearable
					filterable
					searchable
					:options="projects"
					:reduce="(o) => o.id"
					placeholder="Select a project"
				/>
			</div>
			<div class="d-flex justify-content-end">
				<b-button variant="primary" :disabled="!disable" @click="fetchSubmissions">Apply</b-button>
			</div>
		</b-card>

		<base-table ref="submissionsTable" :headers="headers" :items="submissions" table-top paginate>
			<template #cell(created_at)="{ item }">
				<span>{{ new Date(item.created_at).toLocaleDateString() }}</span>
			</template>
		</base-table>
	</div>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import { BButton, BCard } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import api from '@/utils/api';
import { buildQueryString } from '@/utils/helpers';

export default {
	name: 'Submissions',

	components: {
		vSelect,
		BaseTable,
		BButton,
		BCard,
	},

	data: () => ({
		forms: [],
		projects: [],
		submissions: [],
		formId: '',
		cityName: '',
		projectId: '',

		headers: [
			{ key: 'id', label: 'id' },
			{ key: 'form_id', label: 'form id' },
			{ key: 'form.name', label: 'Name' },
			{ key: 'project.SP_PROJID', label: 'Project' },
			{ key: 'project.GOV_AR', label: 'City' },
			{ key: 'user.name', label: 'Username' },
			{ key: 'created_at', label: 'Date' },
		],
	}),

	computed: {
		disable() {
			return !!this.formId;
		},
	},

	async created() {
		const { data: forms } = await store.dispatch('forms/getForms');
		this.forms = forms.map((form) => ({
			id: form.id,
			label: form.name,
		}));

		const projects = await store.dispatch('projects/getProjectShortList');
		this.projects = projects.map((p) => ({
			label: `${p.SP_TITLE} - ${p.SP_PROJID}`,
			id: p.SP_PROJID,
		}));
	},

	methods: {
		async fetchSubmissions(ctx, searchQuery) {
			let path = `reports/submissions/${this.formId}?`;
			if (this.cityName) path += `city_name=${this.cityName}&`;
			if (this.projectId) path += `proj_id=${this.projectId}&`;
			path += `${buildQueryString({ ...ctx, searchQuery })}`;

			const { data } = await api.call({ path, method: 'GET' });

			this.submissions = data.data;

			return data;
		},
	},
};
</script>

<style lang="scss" scoped>
.form-filter {
	gap: 1rem;
}
</style>
